import MarkerIoContext from '@/contexts/MarkerIo/MarkerIo.context';
import { useContext } from 'react';

const useMarkerIo = () => {
  const context = useContext(MarkerIoContext);

  if (context === undefined) {
    throw new Error(
      'useMarkerIo must be used within an MarkerIoContext provider'
    );
  }
  const handleCmdKTrigger = () => {
    // check if marker is loaded if not load it,
    // if loaded and marker is active open fullscreen marker

    if (!context.isMarkerIoLoaded && !context.markerIo) {
      context.loadWidget();
    }

    setTimeout(() => {
      context.markerIo?.capture('fullscreen');
    }, 1000);
  };

  return { ...context, handleCmdKTrigger };
};

export default useMarkerIo;

import { ProjectName } from '@/config';
import { searchProvider } from './consts';
import { SearchItem, SearchScope } from './types';

const fetchSearchResults = async (
  query: string,
  projectName: ProjectName,
  scope: SearchScope
): Promise<SearchItem[]> => {
  return await searchProvider[scope](query, projectName, scope);
};

export default fetchSearchResults;

import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import { CommandKApps } from '@uikit/components/CommandMenu/consts';
import Icon from '@uikit/components/Icon/Icon';
import getCurrentPageAsMagnoliaURL from '@uikit/helpers/getCurrentPageAsMagnoliaURL';
import { useEffect, useState } from 'react';
import styles from './OpenInMagnolia.module.scss';

const OpenInMagnoliaCommandMenuApp = ({
  canPopView,
  currentAppName,
  popView,
  query,
  setQuery,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const [magnoliaUrl, setMagnoliaUrl] = useState<string>();

  useEffect(() => {
    const url = getCurrentPageAsMagnoliaURL();
    if (url) {
      window.open(url, '_blank');
      setMagnoliaUrl(url);
    }
  }, []);

  return (
    <CommandMenu.View
      breadcrumbItems={[currentAppName, ...viewStack]}
      canPopView={canPopView}
      noInput
      onPopView={popView}
      onQueryChange={setQuery}
      query={query}
    >
      <div className={styles.base}>
        <Icon
          className={styles.icon}
          isOutlined
          size="iconSize32"
          variant={magnoliaUrl ? 'user/information' : 'user/monkey-blind'}
        />
        {magnoliaUrl ? (
          <div>
            Die Seite wird in Magnolia als ein neuer Tab geöffnet. Falls nicht,{' '}
            <a href={magnoliaUrl} target="_blank" rel="noreferrer">
              klicke hier.
            </a>
          </div>
        ) : (
          <div>Ups! Der Pfad zu Magnolia konnte nicht erzeugt werden.</div>
        )}
      </div>

      <CommandMenu.Toolbar inputRef={null} />
    </CommandMenu.View>
  );
};

export default OpenInMagnoliaCommandMenuApp;

import HotkeyContext from '@/contexts/Hotkey/Hotkey.context';
import { useContext, useEffect, useId } from 'react';
import { Hotkey, UseHotkeyOptions } from './types';

/**
 * Registers a hotkey that will execute a callback when the user presses the keystroke pattern.
 *
 * ---
 * See the [Usage Guide](https://www.notion.so/neoskop/Hotkey-Hook-Usage-e37b55812db548d5b733cf53ae6addb6) for details and examples.
 *
 * ---
 * @param definition A string that defines the keystroke pattern. Always use the `defineStrokes()` helper to create this.
 * @param callback The function that executes when the hotkey is triggered.
 * @param options An object that allows for additional configuration.
 */
const useHotkey = (
  definition: string,
  callback: () => void,
  options?: UseHotkeyOptions
) => {
  const id = useId();
  const { registerHotkey, unregisterHotkey } = useContext(HotkeyContext);

  useEffect(() => {
    if (options?.disabled) {
      return;
    }

    const [initial, chained] = definition.split(' ');

    const hotkey: Hotkey = {
      callback,
      chainedWith: !!chained ? initial : undefined,
      description: options?.description,
      id,
      scope: options?.scope,
      strokes: !!chained ? chained : initial,
    };

    registerHotkey(hotkey);

    return () => {
      unregisterHotkey(hotkey);
    };
  }, [callback, definition, id, options, registerHotkey, unregisterHotkey]);
};

export default useHotkey;

import { ProjectName } from '@/config';
import { SearchItem, SearchScope } from '@/hooks/useSearch/types';

type SearchConfig = {
  behindFeatureToggle?: boolean;
  description: string;
  products?: SearchItem[];
  quicklinks?: {
    displayText: string;
    url: string;
  }[];
  scopes?: { [P in SearchScope]?: string };
  titleWithoutQuery: string;
  titleWithQuery: string;
};

const searchConfigByProject: { [P in ProjectName]?: SearchConfig } = {
  ecg: {
    description: '',
    quicklinks: [
      {
        displayText: 'Karriere',
        url: 'https://www.enercity-contracting.de/unternehmen/karriere',
      },
      {
        displayText: 'Stadtbeleuchtung Bremerhaven',
        url: 'https://www.enercity-contracting.de/bremerhaven',
      },
      {
        displayText: 'Industriewärme für Hamburger Quartiere',
        url: 'https://www.enercity-contracting.de/referenzen/quartierswaerme-rothenburgsort',
      },
    ],
    titleWithoutQuery: 'Suche - enercity contracting',
    titleWithQuery: '{0} - enercity-contracting.de',
  },
  enercity: {
    description:
      'Suchen Sie nach unseren Produkten, Services und Informationen rund um Strom, Gas, Solar und mehr.',
    products: [
      {
        id: 'power',
        name: 'Strom',
        prefixIcon: 'user/lightning',
        snippet: 'Attraktive Stromtarife für ganz Deutschland.',
        type: 'products',
        url: 'https://www.enercity.de/privatkunden/produkte/strom',
      },
      {
        id: 'gas',
        name: 'Gas',
        prefixIcon: 'user/energy-fire',
        snippet: 'Attraktive Gastarife für ganz Deutschland.',
        type: 'products',
        url: 'https://www.enercity.de/privatkunden/produkte/gas',
      },
      {
        id: 'solar',
        name: 'Solar',
        prefixIcon: 'user/solar',
        snippet: 'Stromkosten senken und unabhängiger sein.',
        type: 'products',
        url: 'https://www.enercity.de/privatkunden/produkte/solar',
      },
      {
        id: 'heat',
        name: 'Wärme',
        prefixIcon: 'user/heat',
        snippet: 'Behagliche Wärme für Ihr Zuhause.',
        type: 'products',
        url: 'https://www.enercity.de/privatkunden/produkte/waerme',
      },
      {
        id: 'electricity',
        name: 'Elektromobilität',
        prefixIcon: 'user/energy-plug',
        snippet: 'Fahren Sie mit enercity auf der Überholspur.',
        type: 'products',
        url: 'https://www.enercity.de/privatkunden/produkte/elektromobilitaet',
      },
      {
        id: 'smarthome',
        name: 'SmartHome',
        prefixIcon: 'user/smarthome',
        snippet: 'Intelligent heizen und Energie sparen.',
        type: 'products',
        url: 'https://www.enercity.de/privatkunden/produkte/waerme/smarte-waerme',
      },
      {
        id: 'water',
        name: 'Wasser',
        prefixIcon: 'user/water-drops',
        snippet: 'Konstant gute Trinkwasserqualität zu fairen Preisen.',
        type: 'products',
        url: 'https://www.enercity.de/privatkunden/produkte/wasser',
      },
      {
        id: 'house-connection',
        name: 'Hausanschluss',
        prefixIcon: 'user/house-connection',
        snippet: 'Umfassende Beratung rund um das Thema Hausanschluss.',
        type: 'products',
        url: 'https://www.enercity.de/privatkunden/produkte/hausanschluss',
      },
      {
        id: 'surf-fon',
        name: 'Telekommunikation',
        prefixIcon: 'user/surf-fon',
        snippet: 'Surf&Fon Tarife — schnelles Internet mit Glasfaser',
        type: 'products',
        url: 'https://www.enercity.de/privatkunden/produkte/glasfaser',
      },
      {
        id: 'training',
        name: 'enercity Schulungen',
        prefixIcon: 'user/training',
        snippet:
          'Qualifikation der Elektrobranche: das SchulungsCenter in Hannover.',
        type: 'products',
        url: 'https://www.enercity.de/geschaeftskunden/produkte/schulungscenter',
      },
      {
        id: 'efficiency',
        name: 'Energieeffizienz',
        prefixIcon: 'user/efficiency',
        snippet: 'Wege zu mehr Energieeffizienz',
        type: 'products',
        url: 'https://www.enercity.de/energieeffizienz',
      },
      {
        id: 'danger-electricity',
        name: 'Netzdienstleistungen',
        prefixIcon: 'user/danger-electricity',
        snippet:
          'Elektrische Versorungssicherheit rund um die Uhr für Ihren Betrieb.',
        type: 'products',
        url: 'https://www.enercity.de/netzdienstleistungen',
      },
      {
        id: 'profit',
        name: 'Direktvermarktung',
        prefixIcon: 'user/profit',
        snippet: 'Direktvermarktung: Ihre Quelle für Zusatzerlöse.',
        type: 'products',
        url: 'https://www.enercity.de/firmenkunden/business-accounts/dienstleistungen/direktvermarktung/index.html',
      },
      {
        id: 'smartbusiness',
        name: 'Smart Business',
        prefixIcon: 'user/smarthome',
        snippet: 'Smarte Lösungen für Unternehmen, Gebäude und Städte.',
        type: 'products',
        url: 'https://www.enercity.de/smartbusiness',
      },
      {
        id: 'street-light',
        name: 'BeleuchtungsService',
        prefixIcon: 'user/street-light',
        snippet: 'enercity BeleuchtungsService für Außenbeleuchtungen.',
        type: 'products',
        url: 'https://www.enercity.de/firmenkunden/business-accounts/dienstleistungen/beleuchtungsservice',
      },
      {
        id: 'calendar',
        name: 'Stichtagsabrechnung',
        prefixIcon: 'user/calendar',
        snippet: 'zum 31.12 für Kunden der Wohnungswirtschaft.',
        type: 'products',
        url: 'https://www.enercity.de/firmenkunden/business-accounts/dienstleistungen/stichtagsabrechnung',
      },
    ],
    quicklinks: [
      {
        displayText: 'Strom- & Gastarife',
        url: 'https://www.enercity.de/privatkunden/produkte/strom-gas',
      },
      {
        displayText: 'Umzug melden',
        url: 'https://www.enercity.de/privatkunden/service/vertragsaenderung/umzug',
      },
      {
        displayText: 'Kontakt',
        url: 'https://www.enercity.de/kontakt',
      },
      {
        displayText: 'Störung',
        url: 'https://www.enercity.de/kontakt/stoerung',
      },
      {
        displayText: 'Über Uns',
        url: 'https://www.enercity.de/unternehmen',
      },
    ],
    scopes: {
      products: 'Produkte',
      faq: 'FAQ',
      magazine: 'Magazin',
      jobs: 'Jobs',
      press: 'Presse',
      downloads: 'Downloads',
    },
    titleWithoutQuery: 'Suche - enercity AG',
    titleWithQuery: '{0} - enercity.de',
  },
  eng: {
    description:
      'Suchen Sie nach unseren Services rund um Ihre Versorgungssicherheit. Zähler, Anschließen, Einspeisen und mehr.',
    quicklinks: [
      {
        displayText: 'Zählerstand mitteilen',
        url: 'https://www.enercity-netz.de/zaehler/zaehlerstand',
      },
      {
        displayText: 'Inbetriebsetzungsauftrag erstellen',
        url: 'https://www.enercity-netz.de/partner/installateure',
      },
      {
        displayText: 'Netzanschluss',
        url: 'https://www.enercity-netz.de/anschliessen/netzanschluss',
      },
      {
        displayText: 'Ladesäule anmelden',
        url: 'https://www.enercity-netz.de/anschliessen/ladesaeule',
      },
      {
        displayText: 'PV-Anlage anmelden',
        url: 'https://www.enercity-netz.de/einspeisen',
      },
    ],
    titleWithoutQuery: 'Suche - enercity netz',
    titleWithQuery: '{0} - enercity-netz.de',
  },
  'mein-h-gas': {
    description: 'Suchen Sie nach allen Infos rund um Ihre Gas-Umstellung.',
    quicklinks: [
      {
        displayText: 'Umstellungsprozess',
        url: 'https://www.mein-h-gas.de/umstellung/umstellungsprozess',
      },
      {
        displayText: 'Sicherheitshinweise',
        url: 'https://www.mein-h-gas.de/informationen/sicherheitshinweise',
      },
      {
        displayText: 'Infofilme',
        url: 'https://www.mein-h-gas.de/informationen/infofilme',
      },
      {
        displayText: 'Fernwärmesatzung',
        url: 'https://www.mein-h-gas.de/informationen/fernwaermesatzung',
      },
      {
        displayText: 'Fragen & Antworten',
        url: 'https://www.mein-h-gas.de/informationen/faq',
      },
    ],
    titleWithoutQuery: 'Suche - mein H-Gas',
    titleWithQuery: '{0} - mein-h-gas.de',
  },
};

export const SEARCH_SCOPE_NAMES: Record<SearchScope, string> = {
  downloads: 'Downloads',
  faq: 'FAQ',
  global: '',
  jobs: 'Jobs',
  magazine: 'Magazin',
  press: 'Presse',
  products: 'Produkte',
};

const currentProjectSearchConfig =
  searchConfigByProject[
    (process.env.PROJECT_NAME || 'enercity') as ProjectName
  ];

export default {
  ...currentProjectSearchConfig,
  searchEnabled: currentProjectSearchConfig !== undefined,
  searchRequiresFeatureToggle:
    !!currentProjectSearchConfig?.behindFeatureToggle,
} as const;

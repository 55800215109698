import { bingSearchProvider } from './searchProviders/bingSearchProvider';
import { globalSearchProvider } from './searchProviders/globalSearchProvider';
import { jobSearchProvider } from './searchProviders/jobsSearchProvider';
import { productSearchProvider } from './searchProviders/productSearchProvider';
import { SearchProvider, SearchScope } from './types';

export const searchProvider: { [P in SearchScope]: SearchProvider } = {
  global: globalSearchProvider,
  downloads: bingSearchProvider,
  faq: bingSearchProvider,
  jobs: jobSearchProvider,
  magazine: bingSearchProvider,
  press: bingSearchProvider,
  products: productSearchProvider,
};

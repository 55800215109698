import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import useMarkerIo from '@/hooks/useMarkerIo/useMarkerIo';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import { CommandKApps } from '@uikit/components/CommandMenu/consts';
import Icon from '@uikit/components/Icon/Icon';
import { useEffect, useRef, useState } from 'react';
import styles from './Settings.module.scss';

const SettingsCommandMenuApp = ({
  canPopView,
  currentAppName,
  popView,
  query,
  setQuery,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { markerIo } = useMarkerIo();
  const [isWidgetVisible, setIsWidgetVisible] = useState(false);

  useEffect(() => {
    setIsWidgetVisible(markerIo?.isVisible() ?? false);
  }, [markerIo]);

  const handleToggleMarkerIoWidget = () => {
    if (isWidgetVisible) {
      markerIo?.hide();
      setIsWidgetVisible(false);
    } else {
      markerIo?.show();
      setIsWidgetVisible(true);
    }
  };

  return (
    <CommandMenu.View
      breadcrumbItems={[currentAppName, ...viewStack]}
      canPopView={canPopView}
      inputRef={inputRef}
      onPopView={popView}
      onQueryChange={setQuery}
      query={query}
    >
      <CommandMenu.List>
        <CommandMenu.Group heading="Marker.io">
          <CommandMenu.Item
            disabled={!markerIo}
            onSelect={handleToggleMarkerIoWidget}
          >
            <Icon isOutlined size="iconSize18" variant="user/gear" />
            <span>Widget anzeigen</span>

            {isWidgetVisible && (
              <Icon
                className={styles.indicator}
                isOutlined
                size="iconSize18"
                variant="action/checkmark"
              />
            )}
          </CommandMenu.Item>
        </CommandMenu.Group>
      </CommandMenu.List>

      <CommandMenu.Toolbar canSelect inputRef={inputRef} />
    </CommandMenu.View>
  );
};

export default SettingsCommandMenuApp;

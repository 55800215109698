import { useCallback } from 'react';
import useLocalStorage from '../useLocalStorage/useLocalStorage';
import {
  DEFAULT_FEATURE_TOGGLE_VALUES,
  ENABLED_FEATURE_TOGGLE_VALUES,
  FEATURE_TOGGLES,
} from './consts';
import { FeatureToggleValues } from './types';

type FeatureToggles = FeatureToggleValues<typeof FEATURE_TOGGLES>;

const useFeatureToggle = () => {
  const [{ '*': enableAll, ...values }, setValues] =
    useLocalStorage<FeatureToggles>('features', DEFAULT_FEATURE_TOGGLE_VALUES);

  const featureToggles = enableAll
    ? ENABLED_FEATURE_TOGGLE_VALUES
    : { ...DEFAULT_FEATURE_TOGGLE_VALUES, ...values };

  const setFeatureToggle = useCallback(
    (feature: keyof FeatureToggles, value: boolean) => {
      setValues({ '*': enableAll, ...values, [feature]: value });
    },
    [enableAll, setValues, values]
  );

  return {
    featureToggles,
    setFeatureToggle,
    setFeatureToggles: setValues,
  };
};

export default useFeatureToggle;

const getCurrentPageAsMagnoliaURL = () => {
  const host = window.location.host;
  const pathname = window.location.pathname;
  const project = process.env.PROJECT_NAME;

  if (host && pathname && project) {
    if (host.includes('localhost')) {
      return `https://localhost:8080/.magnolia/admincentral#app:pages-app:detail;${pathname}:edit`;
    }
    if (host.includes('neoskop.dev')) {
      return `https://magnolia.${host}/.magnolia/admincentral#app:pages-app:detail;${pathname}:edit`;
    }

    const subdomain = host.slice(0, host.indexOf('.'));
    if (subdomain === 'www') {
      return `https://magnolia-author.aws-prod.enercity.de/.magnolia/admincentral#app:pages-app:detail;/${project}${pathname}:edit`;
    }
    return `https://magnolia-author.${subdomain}.enercity.de/.magnolia/admincentral#app:pages-app:detail;/${project}${pathname}:edit`;
  }
};

export default getCurrentPageAsMagnoliaURL;

import { CommandMenuApp } from '@/hooks/useCommandK/types';
import BuildInfoCommandMenuApp from '../CommandMenuApps/BuildInfo/BuildInfo';
import EnercityPlusPreviewsCommandMenuApp from '../CommandMenuApps/EnercityPlusPreviews/EnercityPlusPreviews';
import FeatureTogglesCommandMenuApp from '../CommandMenuApps/FeatureToggles/FeatureToggles';
import OpenInDirectusCommandMenuApp from '../CommandMenuApps/OpenInDirectus/OpenInDirectus';
import OpenInMagnoliaCommandMenuApp from '../CommandMenuApps/OpenInMagnolia/OpenInMagnolia';
import SearchCommandMenuApp from '../CommandMenuApps/Search/Search';
import SettingsCommandMenuApp from '../CommandMenuApps/Settings/Settings';
import SystemInfoCommandMenuApp from '../CommandMenuApps/SystemInfo/SystemInfo';
import UptimeRobotMonitorsCommandMenuApp from '../CommandMenuApps/UptimeRobotMonitors/UptimeRobotMonitors';

export type CommandKApps =
  | 'build-info'
  | 'enercity-plus-previews'
  | 'feature-toggles'
  | 'open-in-directus'
  | 'open-in-magnolia'
  | 'search'
  | 'settings'
  | 'system-info'
  | 'uptime-monitors';

export const COMMAND_K_APPS: Record<
  CommandKApps,
  CommandMenuApp<CommandKApps>
> = {
  'build-info': {
    name: 'Build-Informationen',
    render: (props) => <BuildInfoCommandMenuApp {...props} />,
  },
  'enercity-plus-previews': {
    name: 'Enercity+ Previews',
    render: (props) => <EnercityPlusPreviewsCommandMenuApp {...props} />,
  },
  'feature-toggles': {
    name: 'Experimentelle Features',
    render: (props) => <FeatureTogglesCommandMenuApp {...props} />,
  },
  'open-in-directus': {
    name: 'In Directus öffnen',
    render: (props) => <OpenInDirectusCommandMenuApp {...props} />,
  },
  'open-in-magnolia': {
    name: 'In Magnolia öffnen',
    render: (props) => <OpenInMagnoliaCommandMenuApp {...props} />,
  },
  search: {
    name: 'Suche',
    render: (props) => <SearchCommandMenuApp {...props} />,
  },
  settings: {
    name: 'Einstellungen',
    render: (props) => <SettingsCommandMenuApp {...props} />,
  },
  'system-info': {
    name: 'Client-Informationen',
    render: (props) => <SystemInfoCommandMenuApp {...props} />,
  },
  'uptime-monitors': {
    name: 'Servicestatus',
    render: (props) => <UptimeRobotMonitorsCommandMenuApp {...props} />,
  },
};

import { UseNavigateOptions } from './interfaces';
import useSafeRouter from '../useSafeRouter/useSafeRouter';

const useNavigate = () => {
  const { replace, push } = useSafeRouter();

  return (to: string, options?: UseNavigateOptions) =>
    (options?.replace ? replace : push)(to, to, options);
};

export default useNavigate;

import { formatDate, FormatDateType } from '@/helpers/date';
import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import { CommandKApps } from '@uikit/components/CommandMenu/consts';
import Icon from '@uikit/components/Icon/Icon';
import { copyToClipboard } from '@uikit/helpers/copyToClipboard';
import { useEffect, useState } from 'react';
import styles from './BuildInfo.module.scss';

const BUILD_VERSION = process.env.BUILD_VERSION;
const BUILD_DATE = process.env.BUILD_DATE;

const BuildInfoCommandMenuApp = ({
  canPopView,
  currentAppName,
  popView,
  query,
  setQuery,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const [isCopied, setIsCopied] = useState(false);

  const version = BUILD_VERSION ?? 'unbekannt';
  const date = BUILD_DATE
    ? formatDate(new Date(BUILD_DATE), FormatDateType.Long)
    : 'unbekannt';

  const handleCopyToClipboard = () => {
    copyToClipboard(JSON.stringify({ version, date }));
    setIsCopied(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 3500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  return (
    <>
      <CommandMenu.Badge isVisible={isCopied} variant="success">
        In Zwischenablage kopiert
      </CommandMenu.Badge>

      <CommandMenu.View
        breadcrumbItems={[currentAppName, ...viewStack]}
        canPopView={canPopView}
        noInput
        onPopView={popView}
        onQueryChange={setQuery}
        query={query}
      >
        <CommandMenu.List>
          <CommandMenu.Item onSelect={handleCopyToClipboard}>
            <Icon isOutlined size="iconSize18" variant="action/copy" />
            <span>In Zwischenablage kopieren</span>
          </CommandMenu.Item>

          <div className={styles.base}>
            <Icon
              className={styles.icon}
              isOutlined
              size="iconSize32"
              variant="user/build"
            />
            <div>Version {version}</div>
            <div>Build Datum {date}</div>
          </div>
        </CommandMenu.List>

        <CommandMenu.Toolbar canSelect inputRef={null} />
      </CommandMenu.View>
    </>
  );
};

export default BuildInfoCommandMenuApp;

import { enercityEndpoints } from '@/config/endpoints';
import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import useCommandMenuContext from '@/hooks/useCommandMenuContext/useCommandMenuContext';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import { CommandKApps } from '@uikit/components/CommandMenu/consts';
import Icon from '@uikit/components/Icon/Icon';
import { useEffect, useState } from 'react';
import styles from './OpenInDirectus.module.scss';

const getDirectusDealUrl = (dealId: string) =>
  `${enercityEndpoints.directusUrl}/admin/content/benefits_deal/${dealId}`;

const getDirectusOverviewUrl = () =>
  `${enercityEndpoints.directusUrl}/admin/content/benefits_overview_page`;

const OpenInDirectusCommandMenuApp = ({
  canPopView,
  currentAppName,
  popView,
  query,
  setQuery,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const [directusUrl, setDirectusUrl] = useState<string>();
  const { pageData } = useCommandMenuContext();

  useEffect(() => {
    const url = !!pageData
      ? getDirectusDealUrl(pageData)
      : getDirectusOverviewUrl();

    if (url) {
      window.open(url, '_blank');
      setDirectusUrl(url);
    }
  }, [pageData]);

  return (
    <CommandMenu.View
      breadcrumbItems={[currentAppName, ...viewStack]}
      canPopView={canPopView}
      noInput
      onPopView={popView}
      onQueryChange={setQuery}
      query={query}
    >
      <div className={styles.base}>
        <Icon
          className={styles.icon}
          isOutlined
          size="iconSize32"
          variant={directusUrl ? 'user/information' : 'user/monkey-blind'}
        />
        {directusUrl ? (
          <div>
            Die Seite wird in Directus als ein neuer Tab geöffnet. Falls nicht,{' '}
            <a href={directusUrl} target="_blank" rel="noreferrer">
              klicke hier.
            </a>
          </div>
        ) : (
          <div>Ups! Der Pfad zu Directus konnte nicht erzeugt werden.</div>
        )}
      </div>

      <CommandMenu.Toolbar inputRef={null} />
    </CommandMenu.View>
  );
};

export default OpenInDirectusCommandMenuApp;

const getSystemInfo = () => {
  let presumedEngine = 'unknown/old';
  const userAgent = navigator?.userAgent ?? '';

  const gecko = userAgent.indexOf('Gecko/');
  if (gecko !== -1) {
    const end = userAgent.indexOf(' ', gecko);
    presumedEngine = userAgent.slice(gecko, end) + ' (usually Firefox)';
  }
  const blink = userAgent.indexOf('Chrome/');
  if (blink !== -1) {
    const end = userAgent.indexOf(' ', blink);
    presumedEngine = userAgent.slice(blink, end) + ' (usually Chrome, Edge)';
  }
  const webkit = userAgent.indexOf('AppleWebKit/');
  if (webkit !== -1 && blink === -1) {
    const end = userAgent.indexOf(' ', webkit);
    presumedEngine = userAgent.slice(webkit, end) + ' (usually Safari)';
  }

  return {
    userAgent,
    pixelRatio: window?.devicePixelRatio,
    viewportWidth: window?.innerWidth,
    viewportHeight: window?.innerHeight,
    hasTouch: navigator?.maxTouchPoints > 0,
    presumedEngine,
  };
};

export default getSystemInfo;

import searchConfig from '@/page-templates/SearchPage/consts';
import Fuse from 'fuse.js';
import { SearchProvider } from '../types';

export const productSearchProvider: SearchProvider = async (query) => {
  if (!searchConfig.products) {
    return [];
  }

  const fuse = new Fuse(searchConfig.products, {
    ignoreLocation: true,
    keys: ['name', 'snippet'],
    threshold: 0.25,
  });

  return fuse.search(query).map(({ item }) => item);
};

import config from '@/config';
import { useQuery } from 'react-query';
import fetchSearchResults from './fetchSearchResults';
import { SearchScope } from './types';

const useSearch = (query: string, scope: SearchScope = 'global') => {
  return useQuery(
    ['search', { query, scope }],
    () => fetchSearchResults(query, config.currentProjectName, scope),
    {
      enabled: !!query && !!scope,
      retry: false,
    }
  );
};

export default useSearch;

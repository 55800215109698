const isMacOS = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  let platform: string | undefined = undefined;

  if (
    'userAgentData' in window.navigator &&
    typeof window.navigator.userAgentData === 'object' &&
    'platform' in window.navigator.userAgentData!
  ) {
    platform = window.navigator.userAgentData!.platform as string;
  } else if ('platform' in window.navigator) {
    platform = window.navigator.platform;
  }

  return /^mac/gi.test(platform ?? '');
};

export default isMacOS;

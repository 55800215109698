import useCommandMenuContext from '@/hooks/useCommandMenuContext/useCommandMenuContext';
import { Command } from 'cmdk';
import { forwardRef, useEffect } from 'react';
import { CommandMenuItemWithDataProps } from './types';

export const CommandMenuItem = Command.Item;

export const CommandMenuItemWithData = forwardRef<
  HTMLDivElement,
  CommandMenuItemWithDataProps
>(({ data, value, ...props }, ref) => {
  const { registerItem, unregisterItem } = useCommandMenuContext();

  useEffect(() => {
    registerItem(value, data);

    return () => {
      unregisterItem(value);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, value]);

  return <Command.Item {...props} ref={ref} value={value} />;
});

CommandMenuItemWithData.displayName = 'CommandMenuItemWithData';

export default CommandMenuItemWithData;

import isMacOS from '@/helpers/isMacOS';
import useCommandMenuContext from '@/hooks/useCommandMenuContext/useCommandMenuContext';
import { defineStrokes } from '@/hooks/useHotkey/helper';
import useHotkey from '@/hooks/useHotkey/useHotkey';
import * as Popover from '@radix-ui/react-popover';
import clsx from 'clsx';
import { Command, useCommandState } from 'cmdk';
import { KeyboardEventHandler, useState } from 'react';
import styles from './Toolbar.module.scss';
import { CommandMenuToolbarProps } from './types';

const CommandMenuToolbar = ({
  canSelect,
  children,
  inputRef,
  renderAddon,
}: CommandMenuToolbarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentValue = useCommandState((s) => s.value);
  const { getData } = useCommandMenuContext();
  const isMac = isMacOS();

  const data = getData(currentValue);
  const hasActions = typeof data !== 'undefined';

  const handleCloseAutoFocus = (event: Event) => {
    event.preventDefault();

    inputRef?.current?.focus();
  };

  const handleInteraction: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
    } else if (event.key === 'Backspace') {
      event.stopPropagation();
      setIsOpen((v) => !v);
    }
  };

  useHotkey(defineStrokes('Meta+K'), () => setIsOpen((v) => !v), {
    description: 'Weitere Aktionen umschalten',
    disabled: !hasActions,
    scope: 'Command Menü',
  });

  return (
    <>
      {isOpen && <div className={styles.cursorTrap} />}

      {data && renderAddon && (
        <div className={styles.addon}>
          {renderAddon?.({ data, value: currentValue })}
        </div>
      )}

      <Popover.Root modal onOpenChange={setIsOpen} open={isOpen}>
        <div
          className={clsx(styles.base, {
            [styles.noBorder]: data && renderAddon,
          })}
        >
          <div className={clsx(styles.item, styles.isLeft)}>
            <span>Schließen</span>
            <kbd>ESC</kbd>
          </div>

          <div
            className={clsx(styles.item, {
              [styles.isDisabled]: !canSelect,
            })}
          >
            <span>Auswählen</span>
            <kbd>Enter</kbd>
          </div>

          <Popover.Anchor asChild>
            <div
              className={clsx(styles.item, {
                [styles.isActive]: isOpen,
                [styles.isDisabled]: !hasActions,
              })}
            >
              <span>Weitere Aktionen</span>
              <kbd>{isMac ? '⌘' : 'CTRL'}</kbd>
              <kbd>K</kbd>
            </div>
          </Popover.Anchor>

          <Popover.Content
            align="end"
            alignOffset={0}
            asChild
            className={styles.popover}
            onCloseAutoFocus={handleCloseAutoFocus}
            onKeyDown={handleInteraction}
            side="top"
            sideOffset={16}
          >
            <Command loop>
              {children?.({ data, setIsOpen, value: currentValue })}
            </Command>
          </Popover.Content>
        </div>
      </Popover.Root>
    </>
  );
};

export default CommandMenuToolbar;

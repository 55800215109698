import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import { BadgeVariant } from '@uikit/components/CommandMenu/Badge/types';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import { CommandKApps } from '@uikit/components/CommandMenu/consts';
import Icon from '@uikit/components/Icon/Icon';
import clsx from 'clsx';
import useUptimeRobotMonitorsData from './hooks/useUptimeRobotMonitorsData';
import {
  GetUptimeRobotMonitorsDataResponse,
  Status,
  UptimeBadgeVariant,
} from './types';
import styles from './UptimeRobotMonitors.module.scss';

const statusNames: Record<Status, string> = {
  0: 'Nicht überwacht',
  1: 'Unbekannt',
  2: 'Betriebsbereit',
  8: 'Vmtl. ausgefallen',
  9: 'Ausgefallen',
};

const statusBadgeVariants: Record<Status, UptimeBadgeVariant> = {
  0: 'warning',
  1: 'warning',
  2: 'success',
  8: 'error',
  9: 'error',
};

const getSummary = (
  data?: GetUptimeRobotMonitorsDataResponse | null
): { summaryStatus: BadgeVariant; summaryLabel: string } => {
  if (!data) {
    return { summaryStatus: 'warning', summaryLabel: 'Fehler' };
  }

  if (data.stats.downCount === 0) {
    if (data.stats.warningCount > 0) {
      return {
        summaryStatus: 'warning',
        summaryLabel: 'Manche Status sind unbekannt',
      };
    }
    return {
      summaryStatus: 'success',
      summaryLabel: 'Alle Dienste sind betriebsbereit',
    };
  }

  if (data.stats.upCount > 0) {
    return {
      summaryStatus: 'error',
      summaryLabel: 'Manche Dienste sind ausgefallen',
    };
  }

  return {
    summaryStatus: 'error',
    summaryLabel: 'Alle Dienste sind ausgefallen',
  };
};

const UptimeRobotMonitorsCommandMenuApp = ({
  canPopView,
  currentAppName,
  popView,
  query,
  setQuery,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const { data, status } = useUptimeRobotMonitorsData();
  const monitors = data?.monitors;

  const handleOpen = (url: string) => {
    window.open(url, '_blank');
  };

  const { summaryLabel, summaryStatus } = getSummary(data);

  return (
    <CommandMenu.View
      breadcrumbItems={[currentAppName, ...viewStack]}
      canPopView={canPopView}
      onPopView={popView}
      onQueryChange={setQuery}
      query={query}
    >
      <CommandMenu.Badge
        variant={summaryStatus}
        isVisible={monitors && monitors.length > 0}
      >
        {summaryLabel}
      </CommandMenu.Badge>

      <CommandMenu.List>
        <CommandMenu.Group heading="Services">
          {!data && status !== 'error' && (
            <CommandMenu.Item>
              <CommandMenu.Spinner />
              <span>Lade Monitore...</span>
            </CommandMenu.Item>
          )}

          {((!data && status === 'error') || monitors?.length === 0) && (
            <CommandMenu.Item>
              <Icon isOutlined size="iconSize18" variant="action/search" />
              <span>Keine Ergebnisse gefunden</span>
            </CommandMenu.Item>
          )}

          {monitors &&
            monitors.length > 0 &&
            monitors.map(({ friendly_name, status, id }) => {
              const url = `https://status.enercity.dev/${id}`;

              return (
                <CommandMenu.Item
                  className={styles.item}
                  key={id}
                  onSelect={() => handleOpen(url)}
                >
                  <span>{friendly_name}</span>
                  <span
                    className={clsx(
                      styles.statusBadge,
                      styles[statusBadgeVariants[status]]
                    )}
                  >
                    {statusNames[status]}
                  </span>
                </CommandMenu.Item>
              );
            })}
        </CommandMenu.Group>

        <CommandMenu.Group heading="Detaillierte Übersicht">
          <CommandMenu.Item
            onSelect={() => handleOpen('https://status.enercity.dev')}
          >
            <Icon isOutlined size="iconSize18" variant="action/external-link" />
            <span>Statusseite öffnen</span>
          </CommandMenu.Item>
        </CommandMenu.Group>
      </CommandMenu.List>

      <CommandMenu.Toolbar canSelect inputRef={null} />
    </CommandMenu.View>
  );
};

export default UptimeRobotMonitorsCommandMenuApp;

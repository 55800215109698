import Icon from '@uikit/components/Icon/Icon';
import { Command, useCommandState } from 'cmdk';
import { ReactNode } from 'react';

const CommandMenuList = ({
  children,
  noEmpty = false,
}: {
  children?: ReactNode;
  noEmpty?: boolean;
}) => {
  const search = useCommandState((state) => state.search);

  return (
    <Command.List>
      <>
        {!noEmpty && (
          <Command.Empty>
            <Icon isOutlined size="iconSize18" variant="action/search" />
            <span>{`Keine Ergebnisse ${
              search ? `für "${search}" ` : ''
            }gefunden`}</span>
          </Command.Empty>
        )}

        {children}
      </>
    </Command.List>
  );
};

export default CommandMenuList;

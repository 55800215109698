import clsx from 'clsx';
import styles from './Badge.module.scss';
import { BadgeProps } from './types';

const Badge = ({
  children,
  className,
  isVisible = true,
  onClick,
  variant,
}: BadgeProps) => {
  const Tag = onClick ? 'button' : 'span';

  return (
    <Tag
      className={clsx(styles.base, className, styles[variant], {
        [styles.isClickable]: !!onClick,
        [styles.isVisible]: isVisible,
      })}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};

export default Badge;

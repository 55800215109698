import * as ajax from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import { useQuery } from 'react-query';
import { DirectusBenefitsDeal } from './types';

const JSON_PATH = `/json-data/benefits-preview-deals.json`;

const getBenefitsPreviewData =
  (path: string) => async (): Promise<DirectusBenefitsDeal[] | null> => {
    try {
      const data =
        (await ajax.getJsonCached<DirectusBenefitsDeal[]>(path)) ?? null;

      return data;
    } catch (error) {
      logger.log('getBenefitsPreviewData', error);
      return null;
    }
  };

export default function useBenefitsPreviewData() {
  return useQuery(
    ['benefitsPreviewData', JSON_PATH],
    getBenefitsPreviewData(JSON_PATH),
    {
      placeholderData: null,
    }
  );
}

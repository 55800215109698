import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import {
  DEFAULT_FEATURE_TOGGLE_VALUES,
  ENABLED_FEATURE_TOGGLE_VALUES,
  FEATURE_TOGGLES,
} from '@/hooks/useFeatureToggle/consts';
import useFeatureToggle from '@/hooks/useFeatureToggle/useFeatureToggle';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import { CommandKApps } from '@uikit/components/CommandMenu/consts';
import Icon from '@uikit/components/Icon/Icon';
import { useRef } from 'react';
import styles from './FeatureToggles.module.scss';

const FeatureTogglesCommandMenuApp = ({
  canPopView,
  currentAppName,
  popView,
  query,
  setQuery,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const hasChanged = useRef<boolean>();
  const { featureToggles, setFeatureToggle, setFeatureToggles } =
    useFeatureToggle();

  const automaticActivation = featureToggles['*'];

  const setFeature = (key: string, value: boolean) => {
    hasChanged.current = true;
    setFeatureToggle(key as keyof typeof FEATURE_TOGGLES, value);
  };

  const setFeatures = (value: boolean) => {
    hasChanged.current = true;

    const { '*': _enableAll, ...values } = value
      ? ENABLED_FEATURE_TOGGLE_VALUES
      : DEFAULT_FEATURE_TOGGLE_VALUES;

    setFeatureToggles({ '*': automaticActivation, ...values });
  };

  return (
    <>
      {hasChanged.current && (
        <CommandMenu.Badge
          isVisible={hasChanged.current}
          onClick={() => window.location.reload()}
          variant="warning"
        >
          Klicken, um den Tab zu aktualisieren
        </CommandMenu.Badge>
      )}
      <CommandMenu.View
        breadcrumbItems={[currentAppName, ...viewStack]}
        canPopView={canPopView}
        onPopView={popView}
        onQueryChange={setQuery}
        query={query}
      >
        <CommandMenu.List>
          {automaticActivation && (
            <CommandMenu.Group heading="Automatische Aktivierung">
              <CommandMenu.Item
                onSelect={() => setFeature('*', !automaticActivation)}
              >
                <Icon isOutlined size="iconSize18" variant="user/tick-shape" />
                <span>Automatische Aktivierung</span>

                {automaticActivation && (
                  <Icon
                    className={styles.indicator}
                    isOutlined
                    size="iconSize18"
                    variant="action/checkmark"
                  />
                )}
              </CommandMenu.Item>
            </CommandMenu.Group>
          )}

          <CommandMenu.Group heading="Schnellaktionen">
            <CommandMenu.Item
              disabled={automaticActivation}
              onSelect={() => setFeatures(true)}
            >
              <Icon isOutlined size="iconSize18" variant="user/item-list" />
              <span>Alle Features aktivieren</span>
            </CommandMenu.Item>

            <CommandMenu.Item
              disabled={automaticActivation}
              onSelect={() => setFeatures(false)}
            >
              <Icon isOutlined size="iconSize18" variant="user/item-list" />
              <span>Alle Features deaktivieren</span>
            </CommandMenu.Item>
          </CommandMenu.Group>

          <CommandMenu.Group heading="Alle Features">
            {Object.entries(FEATURE_TOGGLES)
              .sort(([_a, a], [_b, b]) =>
                a.displayName.localeCompare(b.displayName)
              )
              .map(([key, value]) => (
                <CommandMenu.Item
                  disabled={automaticActivation}
                  key={key}
                  onSelect={() =>
                    setFeature(
                      key,
                      !featureToggles[key as keyof typeof featureToggles]
                    )
                  }
                >
                  <Icon isOutlined size="iconSize18" variant={value.icon} />
                  <span>{value.displayName}</span>
                  <span className={styles.badge}>{key}</span>

                  {featureToggles[key as keyof typeof featureToggles] && (
                    <Icon
                      className={styles.indicator}
                      isOutlined
                      size="iconSize18"
                      variant="action/checkmark"
                    />
                  )}
                </CommandMenu.Item>
              ))}
          </CommandMenu.Group>
        </CommandMenu.List>

        <CommandMenu.Toolbar canSelect inputRef={null} />
      </CommandMenu.View>
    </>
  );
};

export default FeatureTogglesCommandMenuApp;

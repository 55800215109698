import { enercityEndpoints } from '@/config/endpoints';
import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import useCommandMenuContext from '@/hooks/useCommandMenuContext/useCommandMenuContext';
import { defineStrokes } from '@/hooks/useHotkey/helper';
import useHotkey from '@/hooks/useHotkey/useHotkey';
import useNavigate from '@/hooks/useNavigate/useNavigate';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import { CommandKApps } from '@uikit/components/CommandMenu/consts';
import { defineValue } from '@uikit/components/CommandMenu/defineValue';
import Icon from '@uikit/components/Icon/Icon';
import { copyToClipboard } from '@uikit/helpers/copyToClipboard';
import getBaseUrl from '@uikit/helpers/getBaseUrl';
import { useCommandState } from 'cmdk';
import { useEffect, useRef, useState } from 'react';
import styles from './EnercityPlusPreview.module.scss';
import useBenefitsPreviewData from './hooks/useBenefitsPreviewData';

const EnercityPlusPreviewsCommandMenuApp = ({
  canPopView,
  currentAppName,
  popView,
  query,
  setIsOpen,
  setQuery,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const { data } = useBenefitsPreviewData();
  const baseUrl = getBaseUrl();
  const navigate = useNavigate();

  const handleCopyUrlToClipboard = (url: string, callback?: () => void) => {
    copyToClipboard(url);
    setIsCopied(true);
    callback?.();
  };

  const handleNavigate = (url: string) => {
    navigate(url);
    setIsOpen(false);
  };

  const handleOpenInNewTab = (dealId: string) => {
    const url = `${baseUrl}/vorteile/plus/preview/${dealId}`;
    window.open(url, '_blank');
    setIsOpen(false);
  };

  const handleOpenInDirectus = (dealId: string) => {
    window.open(
      `${enercityEndpoints.directusUrl}/admin/content/benefits_deal/${dealId}`,
      '_blank'
    );
    setIsOpen(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 3500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  const currentValue = useCommandState((s) => s.value);
  const { getData } = useCommandMenuContext();
  const itemData = getData(currentValue);

  useHotkey(
    defineStrokes('Meta+Shift+C'),
    () => handleCopyUrlToClipboard(itemData.url),
    { disabled: !itemData }
  );

  useHotkey(
    defineStrokes('Meta+Shift+D'),
    () => handleOpenInDirectus(itemData.id),
    { disabled: !itemData }
  );

  useHotkey(
    defineStrokes('Meta+Shift+T'),
    () => handleOpenInNewTab(itemData.slug),
    { disabled: !itemData }
  );

  return (
    <>
      <CommandMenu.Badge variant="success" isVisible={isCopied}>
        In Zwischenablage kopiert
      </CommandMenu.Badge>

      <CommandMenu.View
        breadcrumbItems={[currentAppName, ...viewStack]}
        canPopView={canPopView}
        inputRef={inputRef}
        onPopView={popView}
        onQueryChange={setQuery}
        query={query}
      >
        <CommandMenu.List>
          {typeof data === 'undefined' && (
            <CommandMenu.Item>
              <CommandMenu.Spinner />
              <span>Lade Previews...</span>
            </CommandMenu.Item>
          )}

          {data?.length !== 0 && (
            <>
              {data?.map((item) => {
                const { headline, company, id } = item;
                const url = `${baseUrl}/vorteile/plus/preview/${id}`;

                return (
                  <CommandMenu.ItemWithData
                    data={item}
                    onSelect={() => handleNavigate(url)}
                    value={defineValue(company.company_name, headline)}
                    key={id}
                  >
                    {company.company_name} - {headline}
                  </CommandMenu.ItemWithData>
                );
              })}
            </>
          )}

          {data === null && (
            <CommandMenu.Item>
              <Icon isOutlined size="iconSize18" variant="action/search" />
              <span>Keine Ergebnisse gefunden</span>
            </CommandMenu.Item>
          )}
        </CommandMenu.List>

        <CommandMenu.Toolbar inputRef={inputRef} canSelect>
          {({ data, setIsOpen }) => {
            return (
              <CommandMenu.List>
                <CommandMenu.Item
                  onSelect={() =>
                    handleCopyUrlToClipboard(data.url, () => setIsOpen(false))
                  }
                >
                  <span>Link kopieren</span>
                  <CommandMenu.Shortcut
                    className={styles.shortcut}
                    strokes={defineStrokes('Meta+Shift+C')}
                  />
                </CommandMenu.Item>
                <CommandMenu.Item
                  onSelect={() => handleOpenInDirectus(data.id)}
                >
                  <span>In Directus öffnen</span>
                  <CommandMenu.Shortcut
                    className={styles.shortcut}
                    strokes={defineStrokes('Meta+Shift+D')}
                  />
                </CommandMenu.Item>
                <CommandMenu.Item
                  onSelect={() => handleOpenInNewTab(data.slug)}
                >
                  <span>In neuem Tab öffnen</span>
                  <CommandMenu.Shortcut
                    className={styles.shortcut}
                    strokes={defineStrokes('Meta+Shift+T')}
                  />
                </CommandMenu.Item>
              </CommandMenu.List>
            );
          }}
        </CommandMenu.Toolbar>
      </CommandMenu.View>
    </>
  );
};

export default EnercityPlusPreviewsCommandMenuApp;

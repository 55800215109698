import Badge from './Badge/Badge';
import Group from './Group/Group';
import { CommandMenuItem, CommandMenuItemWithData } from './Item/Item';
import List from './List/List';
import Shortcut from './Shortcut/Shortcut';
import Spinner from './Spinner/Spinner';
import Toolbar from './Toolbar/Toolbar';
import View from './View/View';

const CommandMenu = {
  Badge,
  Group,
  Item: CommandMenuItem,
  ItemWithData: CommandMenuItemWithData,
  List,
  Shortcut,
  Spinner,
  Toolbar,
  View,
};

export default CommandMenu;

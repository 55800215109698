import { useCallback, useState } from 'react';
import { defineStrokes } from '../useHotkey/helper';
import useHotkey from '../useHotkey/useHotkey';
import { UseCommandKOptions } from './types';

const useCommandK = <T extends string>({
  apps,
  renderRoot,
}: UseCommandKOptions<T>) => {
  const [currentAppId, setCurrentAppId] = useState<T | 'root'>('root');
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [shouldFilter, setShouldFilter] = useState(true);
  const [viewStack, setViewStack] = useState<string[]>([]);

  const isRoot = currentAppId === 'root';
  const currentApp = isRoot
    ? {
        name: 'Übersicht',
        render: renderRoot,
      }
    : apps[currentAppId];
  const currentViewId = viewStack.at(-1);

  const canPopView = viewStack.length > 0 || !isRoot;

  useHotkey(defineStrokes('Meta+K'), () => setIsOpen(true), {
    description: 'Command Menü öffnen',
  });

  const openApp = (id: T | 'root') => {
    setCurrentAppId(id);
    setQuery('');
  };

  const popView = useCallback(() => {
    if (viewStack.length) {
      setQuery('');
      setViewStack((stack) => {
        const newStack = [...stack];
        newStack.splice(-1, 1);

        return newStack;
      });
    } else if (!isRoot) {
      setQuery('');
      setCurrentAppId('root');
    }
  }, [isRoot, viewStack]);

  const pushView = (viewId: string) => {
    setViewStack((stack) => [...stack, viewId]);
    setQuery('');
  };

  return {
    canPopView,
    currentApp,
    currentAppId,
    currentViewId,
    isOpen,
    isRoot,
    openApp,
    popView,
    pushView,
    query,
    setIsOpen,
    setQuery,
    setShouldFilter,
    setViewStack,
    shouldFilter,
    viewStack,
  };
};

export default useCommandK;

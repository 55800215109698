import { currentProjectName } from '@/config';
import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import useFeatureToggle from '@/hooks/useFeatureToggle/useFeatureToggle';
import useMarkerIo from '@/hooks/useMarkerIo/useMarkerIo';
import usePathname from '@/hooks/usePathname/usePathname';
import searchConfig from '@/page-templates/SearchPage/consts';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import Icon from '@uikit/components/Icon/Icon';
import { useRef } from 'react';
import { CommandKApps } from '../../CommandMenu/consts';
import { isDirectusPage, isMagnoliaPage } from './helper';

const RootCommandMenuApp = ({
  canPopView,
  openApp,
  popView,
  query,
  setIsOpen,
  setQuery,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { featureToggles } = useFeatureToggle();
  const { handleCmdKTrigger } = useMarkerIo();
  const pathname = usePathname();

  const hasSearch =
    searchConfig.searchEnabled &&
    (!searchConfig.searchRequiresFeatureToggle || featureToggles.USE_SEARCH);

  const triggerMarkerIo = () => {
    handleCmdKTrigger();
    setIsOpen(false);
  };

  return (
    <CommandMenu.View
      breadcrumbItems={['Übersicht', ...viewStack]}
      canPopView={canPopView}
      inputRef={inputRef}
      onPopView={popView}
      onQueryChange={setQuery}
      query={query}
    >
      <CommandMenu.List>
        <CommandMenu.Group heading="Aktuelle Seite">
          {isDirectusPage(pathname) && (
            <CommandMenu.Item onSelect={() => openApp('open-in-directus')}>
              <Icon isOutlined size="iconSize18" variant="user/pen" />
              <span>In Directus öffnen</span>
            </CommandMenu.Item>
          )}
          {isMagnoliaPage(pathname) && (
            <CommandMenu.Item onSelect={() => openApp('open-in-magnolia')}>
              <Icon isOutlined size="iconSize18" variant="user/pen" />
              <span>In Magnolia öffnen</span>
            </CommandMenu.Item>
          )}
          {currentProjectName === 'enercity' && (
            <CommandMenu.Item onSelect={triggerMarkerIo}>
              <Icon isOutlined size="iconSize18" variant="user/megaphone" />
              <span>Feedback, Bug, etc. melden</span>
            </CommandMenu.Item>
          )}
        </CommandMenu.Group>

        <CommandMenu.Group heading="Allgemein">
          {hasSearch && (
            <CommandMenu.Item onSelect={() => openApp('search')}>
              <Icon isOutlined size="iconSize18" variant="action/search" />
              <span>Suche</span>
            </CommandMenu.Item>
          )}

          {currentProjectName === 'enercity' && (
            <CommandMenu.Item
              onSelect={() => openApp('enercity-plus-previews')}
            >
              <Icon isOutlined size="iconSize18" variant="action/plus" />
              <span>Enercity+ Previews</span>
            </CommandMenu.Item>
          )}
        </CommandMenu.Group>

        <CommandMenu.Group heading="System">
          <CommandMenu.Item onSelect={() => openApp('settings')}>
            <Icon
              isOutlined
              size="iconSize18"
              variant="user/browser-settings"
            />
            <span>Einstellungen</span>
          </CommandMenu.Item>

          <CommandMenu.Item onSelect={() => openApp('feature-toggles')}>
            <Icon isOutlined size="iconSize18" variant="user/lab" />
            <span>Experimentelle Features</span>
          </CommandMenu.Item>

          <CommandMenu.Item onSelect={() => openApp('build-info')}>
            <Icon isOutlined size="iconSize18" variant="user/information" />
            <span>Build-Informationen</span>
          </CommandMenu.Item>

          <CommandMenu.Item onSelect={() => openApp('system-info')}>
            <Icon isOutlined size="iconSize18" variant="user/information" />
            <span>Client-Informationen</span>
          </CommandMenu.Item>

          <CommandMenu.Item onSelect={() => openApp('uptime-monitors')}>
            <Icon isOutlined size="iconSize18" variant="user/security-camera" />
            <span>Servicestatus</span>
          </CommandMenu.Item>
        </CommandMenu.Group>
      </CommandMenu.List>

      <CommandMenu.Toolbar canSelect inputRef={inputRef} />
    </CommandMenu.View>
  );
};

export default RootCommandMenuApp;

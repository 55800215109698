import { SearchItem, SearchProvider } from '../types';
import { bingSearchProvider } from './bingSearchProvider';
import { jobSearchProvider } from './jobsSearchProvider';
import { productSearchProvider } from './productSearchProvider';

export const globalSearchProvider: SearchProvider = async (
  query,
  projectName
) => {
  const results = await Promise.allSettled([
    productSearchProvider(query, projectName, 'global'),
    bingSearchProvider(query, projectName, 'global'),
    jobSearchProvider(query, projectName, 'global'),
  ]);

  return results
    .filter(
      (result): result is PromiseFulfilledResult<SearchItem[]> =>
        result.status === 'fulfilled'
    )
    .flatMap((result) => result.value)
    .reduce<SearchItem[]>((acc, cur) => {
      if (!acc.some(({ url }) => url === cur.url)) {
        acc.push(cur);
      }

      return acc;
    }, []);
};

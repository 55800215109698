import { FeatureToggleDefinition, FeatureToggleValues } from './types';

/*
  TODO: Replace this function with "satisfies FeatureToggleDefinition" once all our pipelines support Typescript 4.9
*/

const defineFeatureToggles = <T extends FeatureToggleDefinition>(
  definition: T
) => definition;

export const FEATURE_TOGGLES = defineFeatureToggles({
  USE_SEARCH: {
    displayName: 'Suchfunktion',
    icon: 'action/search',
  },
  USE_UMBRELLA_CAMPAIGN: {
    displayName: 'Zeit für Positive Energie',
    icon: 'user/danger-electricity',
  },
  USE_THERMAL_SOLUTION_STAGE: {
    displayName: 'Bühne für Wärmelösungen',
    icon: 'user/heat-pipes',
  },
});

export const DEFAULT_FEATURE_TOGGLE_VALUES = {
  '*': false,
  ...Object.fromEntries(
    Object.keys(FEATURE_TOGGLES).map((key) => [key, false])
  ),
} as FeatureToggleValues<typeof FEATURE_TOGGLES>;

export const ENABLED_FEATURE_TOGGLE_VALUES = {
  '*': true,
  ...Object.fromEntries(Object.keys(FEATURE_TOGGLES).map((key) => [key, true])),
} as FeatureToggleValues<typeof FEATURE_TOGGLES>;

import useKeyPress from '@/hooks/useKeyPress/useKeyPress';
import { useState } from 'react';
import { UseMutationResult } from 'react-query';
import EnercityXNeoskopLogo from '../EnercityXNeoskopLogo/EnercityXNeoskopLogo';
import { FormFieldType } from '../FormField/consts';
import FormField from '../FormField/FormField';
import { FormFieldElement } from '../FormField/interfaces';
import styles from './CommandMenuLogin.module.scss';

const CommandMenuLogin = ({
  mutation: { isLoading, isError, mutate: handleLogin, reset },
}: {
  mutation: UseMutationResult<Response, unknown, string, unknown>;
}) => {
  const [password, setPassword] = useState('');

  useKeyPress('Enter', {
    onKeyDown: () => {
      if (!isLoading) {
        handleLogin(password);
      }
    },
  });

  const handlePasswordChange = (e: React.ChangeEvent<FormFieldElement>) => {
    setPassword(e.target.value);

    if (isError) {
      reset();
    }
  };

  return (
    <div className={styles.base}>
      <EnercityXNeoskopLogo />

      <FormField
        className={styles.password}
        fullWidth
        label="Passwort"
        icon="action/lock"
        type={FormFieldType.Password}
        value={password}
        onChange={handlePasswordChange}
        disabled={isLoading}
        errorMessage={isError ? 'Netter Versuch 😉' : undefined}
      />
    </div>
  );
};

export default CommandMenuLogin;

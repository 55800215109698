import isMacOS from '@/helpers/isMacOS';
import clsx from 'clsx';
import styles from './Shortcut.module.scss';
import { ShortcutProps } from './types';

const MODIFIER_ORDER = ['control', 'shift', 'alt', 'meta'];

const SPECIAL_KEYS: Record<string, string> = {
  backspace: '⌫',
  control: 'ctrl',
  escape: 'esc',
  meta: '⌘',
  minus: '-',
  plus: '+',
  shift: '⇧',
};

const keyToDisplayString = (key: string) => {
  if (SPECIAL_KEYS[key]) {
    return SPECIAL_KEYS[key];
  }

  if (isMacOS() && key === 'alt') {
    return '⌥';
  }

  return key;
};

const Shortcut = ({ className, strokes }: ShortcutProps) => {
  if (!strokes.trim()) {
    return null;
  }

  const [initial, chained] = strokes.trim().split(' ');

  const renderCombination = (combination: string) => {
    const keys = combination.split('+').sort((a, b) => {
      if (MODIFIER_ORDER.indexOf(a) === -1) {
        return 1;
      }

      if (MODIFIER_ORDER.indexOf(b) === -1) {
        return -1;
      }

      return MODIFIER_ORDER.indexOf(a) - MODIFIER_ORDER.indexOf(b);
    });

    return keys.map((key) => (
      <kbd className={styles.key} key={key}>
        {keyToDisplayString(key)}
      </kbd>
    ));
  };

  return (
    <div className={clsx(styles.base, className)}>
      {renderCombination(initial)}

      {!!chained && (
        <>
          <span className={styles.chainIndicator}>→</span>
          {renderCombination(chained)}
        </>
      )}
    </div>
  );
};

export default Shortcut;

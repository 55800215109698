import { debounce } from '@/helpers/debounce';
import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import useCommandMenuContext from '@/hooks/useCommandMenuContext/useCommandMenuContext';
import { defineStrokes } from '@/hooks/useHotkey/helper';
import useHotkey from '@/hooks/useHotkey/useHotkey';
import useNavigate from '@/hooks/useNavigate/useNavigate';
import { SearchScope } from '@/hooks/useSearch/types';
import useSearch from '@/hooks/useSearch/useSearch';
import { SEARCH_SCOPE_NAMES } from '@/page-templates/SearchPage/consts';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import { CommandKApps } from '@uikit/components/CommandMenu/consts';
import Icon from '@uikit/components/Icon/Icon';
import { copyToClipboard } from '@uikit/helpers/copyToClipboard';
import { useCommandState } from 'cmdk';
import { useEffect, useRef, useState } from 'react';
import styles from './Search.module.scss';

function formatUrl(url: string) {
  if (typeof window === 'undefined') {
    return url;
  }

  const { origin } = window.location;

  return url.replace(origin, '');
}

const SearchCommandMenuApp = ({
  canPopView,
  currentAppName,
  popView,
  query,
  setIsOpen,
  setQuery,
  setShouldFilter,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const searchItems = useSearch(searchQuery);
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  const handleQueryChange = (value: string) => {
    setQuery(value);

    debounce((value: string) => setSearchQuery(value.trim()), 500)(value);
  };

  const handleCopyUrlToClipboard = (url: string, callback?: () => void) => {
    copyToClipboard(url);
    setIsCopied(true);
    callback?.();
  };

  const handleNavigate = (url: string) => {
    navigate(url);
    setIsOpen(false);
  };

  const handleOpenInNewTab = (url: string) => {
    window.open(url, '_blank');
    setIsOpen(false);
  };

  useEffect(() => {
    setShouldFilter(false);

    return () => {
      setShouldFilter(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 3500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  const currentValue = useCommandState((s) => s.value);
  const { getData } = useCommandMenuContext();
  const data = getData(currentValue);

  useHotkey(
    defineStrokes('Meta+Shift+C'),
    () => handleCopyUrlToClipboard(data.url),
    { disabled: !data }
  );

  useHotkey(defineStrokes('Meta+Shift+T'), () => handleOpenInNewTab(data.url), {
    disabled: !data,
  });

  return (
    <>
      <CommandMenu.Badge isVisible={isCopied} variant="success">
        In Zwischenablage kopiert
      </CommandMenu.Badge>

      <CommandMenu.View
        breadcrumbItems={[currentAppName, ...viewStack]}
        canPopView={canPopView}
        onPopView={popView}
        onQueryChange={handleQueryChange}
        query={query}
        inputRef={inputRef}
      >
        <CommandMenu.List noEmpty>
          {searchItems.isLoading && (
            <CommandMenu.Item>
              <CommandMenu.Spinner />
              <span>Es wird nach Ergebnissen gesucht...</span>
            </CommandMenu.Item>
          )}

          {!query && (searchItems.data?.length ?? 0) === 0 && (
            <CommandMenu.Item>
              <Icon isOutlined size="iconSize18" variant="action/search" />
              <span>Keine Ergebnisse gefunden</span>
            </CommandMenu.Item>
          )}

          {query &&
            !searchItems.isLoading &&
            (searchItems.data?.length ?? 0) === 0 && (
              <CommandMenu.Item>
                <Icon isOutlined size="iconSize18" variant="action/search" />
                <span>{`Keine Ergebnisse für "${query}" gefunden`}</span>
              </CommandMenu.Item>
            )}

          {searchItems.data?.map((item) => (
            <CommandMenu.ItemWithData
              key={item.id}
              value={item.id}
              data={item}
              onSelect={() => handleNavigate(item.url)}
            >
              {item.prefixIcon && (
                <Icon isOutlined size="iconSize18" variant={item.prefixIcon} />
              )}
              <span>{item.name}</span>

              {item.type && (
                <span className={styles.badge}>
                  {SEARCH_SCOPE_NAMES[item.type as SearchScope]}
                </span>
              )}
            </CommandMenu.ItemWithData>
          ))}
        </CommandMenu.List>

        <CommandMenu.Toolbar
          inputRef={inputRef}
          canSelect
          renderAddon={({ data }) => {
            return <span>{formatUrl(data.url)}</span>;
          }}
        >
          {({ data, setIsOpen }) => {
            return (
              <CommandMenu.List>
                <CommandMenu.Item
                  onSelect={() =>
                    handleCopyUrlToClipboard(data.url, () => setIsOpen(false))
                  }
                >
                  <span>URL kopieren</span>
                  <CommandMenu.Shortcut
                    className={styles.shortcut}
                    strokes={defineStrokes('Meta+Shift+C')}
                  />
                </CommandMenu.Item>
                <CommandMenu.Item onSelect={() => handleOpenInNewTab(data.url)}>
                  <span>In neuem Tab öffnen</span>
                  <CommandMenu.Shortcut
                    className={styles.shortcut}
                    strokes={defineStrokes('Meta+Shift+T')}
                  />
                </CommandMenu.Item>
              </CommandMenu.List>
            );
          }}
        </CommandMenu.Toolbar>
      </CommandMenu.View>
    </>
  );
};

export default SearchCommandMenuApp;

import config, { endpoints } from '@/config';
import * as ajax from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import { useQuery } from 'react-query';
import { GetUptimeRobotMonitorsDataResponse } from '../types';

const PATH = `${config.api.baseUrl}${endpoints.uptimeRobotMonitors}`;

const getUptimeRobotMonitorsData =
  (path: string) =>
  async (): Promise<GetUptimeRobotMonitorsDataResponse | null> => {
    try {
      const data =
        (await ajax.getJson<GetUptimeRobotMonitorsDataResponse>(path)) ?? null;

      return data;
    } catch (error) {
      logger.log('getUptimeRobotMonitorsData', error);
      throw Error();
    }
  };

export default function useUptimeRobotMonitorsData() {
  return useQuery(
    ['uptimeRobotMonitorsData', PATH],
    getUptimeRobotMonitorsData(PATH),
    {
      placeholderData: null,
    }
  );
}

import * as ajax from '@/helpers/ajax';
import { JOBS__DATA_PATH } from '@/hooks/useJobs/consts';
import { JobPosting } from '@/page-templates/JobDetailsPage/interfaces';
import searchConfig from '@/page-templates/SearchPage/consts';
import assetPath from '@uikit/helpers/assetPath';
import Fuse from 'fuse.js';
import { SearchProvider } from '../types';

export const jobSearchProvider: SearchProvider = async (query) => {
  if (!searchConfig.scopes?.jobs) {
    return [];
  }

  const jobs = new Fuse(
    (await ajax.getJsonCached<JobPosting[]>(
      `${assetPath}${JOBS__DATA_PATH}`
    )) ?? [],
    {
      ignoreLocation: true,
      keys: ['description', 'id', 'jobId', 'query', 'title'],
      threshold: 0.25,
    }
  );

  const matchingJobs = jobs.search(query).map(({ item }) => ({
    id: item.id,
    name: item.title || '',
    snippet: `${item.categories?.[0]} | ${item.location}`,
    type: 'jobs',
    url: item.path,
  }));

  return matchingJobs;
};

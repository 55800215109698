import { CommandMenuAppRenderProps } from '@/hooks/useCommandK/types';
import CommandMenu from '@uikit/components/CommandMenu/CommandMenu';
import { CommandKApps } from '@uikit/components/CommandMenu/consts';
import Icon from '@uikit/components/Icon/Icon';
import { copyToClipboard } from '@uikit/helpers/copyToClipboard';
import getSystemInfo from '@uikit/helpers/getSystemInfo';
import { useEffect, useState } from 'react';
import styles from './SystemInfo.module.scss';

const SystemInfoCommandMenuApp = ({
  canPopView,
  currentAppName,
  popView,
  query,
  setQuery,
  viewStack,
}: CommandMenuAppRenderProps<CommandKApps>) => {
  const sysInfo = getSystemInfo();
  const viewport = `${sysInfo.viewportWidth}x${sysInfo.viewportHeight}`;
  const hasTouchText = sysInfo.hasTouch ? 'ja' : 'nein';
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    copyToClipboard(JSON.stringify(sysInfo));
    setIsCopied(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 3500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  return (
    <>
      <CommandMenu.Badge isVisible={isCopied} variant="success">
        In Zwischenablage kopiert
      </CommandMenu.Badge>

      <CommandMenu.View
        breadcrumbItems={[currentAppName, ...viewStack]}
        canPopView={canPopView}
        noInput
        onPopView={popView}
        onQueryChange={setQuery}
        query={query}
      >
        <CommandMenu.List>
          <CommandMenu.Item onSelect={() => handleCopyToClipboard()}>
            <Icon isOutlined size="iconSize18" variant="action/copy" />
            <span>In Zwischenablage kopieren</span>
          </CommandMenu.Item>

          <table className={styles.table}>
            <tbody>
              <tr>
                <th>Erkannte Engine</th>
                <td>{sysInfo.presumedEngine}</td>
              </tr>
              <tr>
                <th>Viewport</th>
                <td>{viewport}</td>
              </tr>
              <tr>
                <th>Pixel Ratio</th>
                <td>{sysInfo.pixelRatio}</td>
              </tr>
              <tr>
                <th>Touch</th>
                <td>{hasTouchText}</td>
              </tr>
              <tr>
                <th>User-Agent</th>
                <td>
                  <code>{sysInfo.userAgent}</code>
                </td>
              </tr>
            </tbody>
          </table>
        </CommandMenu.List>

        <CommandMenu.Toolbar canSelect inputRef={null} />
      </CommandMenu.View>
    </>
  );
};

export default SystemInfoCommandMenuApp;

import CommandMenuContext from '@/contexts/CommandMenu/CommandMenu.context';
import { useContext } from 'react';

const useCommandMenuContext = () => {
  const context = useContext(CommandMenuContext);

  if (context === undefined) {
    throw new Error(
      'useCommandMenuContext must be used within an CommandMenuContext provider'
    );
  }

  return context;
};

export default useCommandMenuContext;

import Icon from '@uikit/components/Icon/Icon';
import clsx from 'clsx';
import { Command } from 'cmdk';
import { useEffect, useRef } from 'react';
import { CommandMenuViewProps } from './types';
import styles from './View.module.scss';

const CommandMenuView = ({
  breadcrumbItems,
  canPopView,
  children,
  className,
  inputRef,
  noInput,
  onPopView,
  onQueryChange,
  query,
}: CommandMenuViewProps) => {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <>
      {!noInput && (
        <nav className={styles.breadcrumb}>
          {breadcrumbItems.map((item) => (
            <div className={styles.breadcrumbItem} key={item}>
              {item}
            </div>
          ))}
        </nav>
      )}
      <header
        className={clsx(styles.header, {
          [styles.withoutInput]: noInput,
        })}
        ref={ref}
      >
        {canPopView && (
          <button className={styles.backButton} onClick={onPopView}>
            <Icon isOutlined size="iconSize16" variant="action/arrow-left" />
          </button>
        )}

        {noInput && (
          <nav className={styles.inlineBreadcrumbs}>
            {breadcrumbItems.map((item) => (
              <div className={styles.breadcrumbItem} key={item}>
                {item}
              </div>
            ))}
          </nav>
        )}

        <Command.Input
          autoFocus
          onValueChange={onQueryChange}
          placeholder="Suche..."
          readOnly={noInput}
          ref={inputRef}
          value={query}
        />
      </header>
      <main className={className}>{children}</main>
    </>
  );
};

export default CommandMenuView;

import useCommandK from '@/hooks/useCommandK/useCommandK';
import useCommandKLogin from '@/hooks/useCommandKLogin/useCommandKLogin';
import RootCommandMenuApp from '@uikit/components/CommandMenuApps/Root/Root';
import { Command } from 'cmdk';
import { useRef } from 'react';
import CommandMenuLogin from '../../CommandMenuLogin/CommandMenuLogin';
import { COMMAND_K_APPS } from '../consts';
import styles from './Base.module.scss';

const CommandMenuBase = () => {
  const portalRef = useRef<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    canPopView,
    currentApp,
    currentViewId,
    isOpen,
    isRoot,
    openApp,
    popView,
    pushView,
    query,
    setIsOpen,
    setShouldFilter,
    setQuery,
    setViewStack,
    shouldFilter,
    viewStack,
  } = useCommandK({
    apps: COMMAND_K_APPS,
    renderRoot: (props) => <RootCommandMenuApp {...props} />,
  });

  const [isAuthorized, mutation] = useCommandKLogin();

  const bounce = () => {
    if (ref.current) {
      ref.current.style.transform = 'scale(0.98)';

      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = 'scale(1)';
        }
      }, 100);
    }
  };

  const handleOpenChange = (value: boolean) => {
    openApp('root');
    setIsOpen(value);
    setViewStack([]);
  };

  return (
    <>
      <Command.Dialog
        container={portalRef.current !== null ? portalRef.current : undefined}
        loop
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            bounce();
          } else if (event.key === 'Backspace' && !query.length && !isRoot) {
            event.preventDefault();
            popView();
            bounce();
          }
        }}
        onOpenChange={handleOpenChange}
        open={isOpen}
        ref={ref}
        shouldFilter={shouldFilter}
      >
        {isAuthorized ? (
          currentApp.render({
            canPopView,
            currentAppName: currentApp.name,
            currentViewId,
            openApp,
            popView,
            pushView,
            query,
            setIsOpen,
            setShouldFilter,
            setQuery,
            viewStack,
          })
        ) : (
          <CommandMenuLogin mutation={mutation} />
        )}
      </Command.Dialog>

      <div ref={portalRef} className={styles.base} />
    </>
  );
};

export default CommandMenuBase;

import isMacOS from '@/helpers/isMacOS';
import { AvailableStrokes, RelaxedStrokes, StrictStrokes } from './types';

const macOS = isMacOS();

/**
 * Detects and constrains the type for chained strokes in a hotkey.
 *
 * ---
 * Chaining a hotkey with *relaxed strokes* makes for a bad user experience. Therefore this type will evaluate to `never` if `TInitial` extends `RelaxedStrokes` thus preventing chaining.
 */
type DetectChainedStrokes<
  TInitial extends AvailableStrokes,
  TChained extends AvailableStrokes | undefined
> = TInitial extends RelaxedStrokes ? never : TChained;

/**
 * Detects and constrains the type for initial strokes in a hotkey.
 *
 * ---
 * Chaining a hotkey with *relaxed strokes* makes for a bad user experience. Therefore this type will evaluate to `StrictStrokes` if `TChained` extends anything thus enforcing a modifier key to trigger chaining.
 */
type DetectInitialStrokes<TChained extends AvailableStrokes | undefined> =
  TChained extends undefined ? RelaxedStrokes : StrictStrokes;

/**
 * Defines a pattern of keystrokes to be used in the `useHotkey` hook.
 * @param initial A string representing the initial strokes required for the hotkey. If `chained` is set, this parameter defines the strokes required to trigger the chaining, otherwise these strokes will trigger the hotkey directly.
 * @param chained A string representing the chained strokes required for the hotkey. Setting this constrains the type of `initial` to `StrictStrokes`.
 */
export const defineStrokes = <
  TInitial extends AvailableStrokes,
  TChained extends AvailableStrokes | undefined = undefined
>(
  initial: DetectInitialStrokes<TChained>,
  chained?: DetectChainedStrokes<TInitial, TChained>
) => {
  const initialStrokes = initial
    .toLowerCase()
    .replace('meta', macOS ? 'meta' : 'control')
    .split('+');
  initialStrokes.sort((a, b) => a.localeCompare(b));

  const chainedStrokes = chained
    ?.toLowerCase()
    .replace('meta', macOS ? 'meta' : 'control')
    .split('+');
  chainedStrokes?.sort((a, b) => a.localeCompare(b));

  let strokes = initialStrokes.join('+');

  if (!!chainedStrokes) {
    strokes += ` ${chainedStrokes.join('+')}`;
  }

  return strokes;
};

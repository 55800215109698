export const isDirectusPage = (pathname: string) => {
  return pathname.startsWith('/vorteile/plus');
};

export const isMagnoliaPage = (pathname: string) => {
  if (pathname === '/karriere/jobsuche/bewerbungstipps') {
    return true;
  }

  return ![
    '/iframes/glass-fiber-optic-map',
    '/karriere/jobsuche',
    '/login',
    '/seitenuebersicht',
    '/staedte',
    '/suche',
    '/vorteile/plus',
  ].some((path) => pathname.startsWith(path));
};

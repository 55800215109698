import config, { endpoints } from '@/config';
import { SearchProvider } from '../types';

export const bingSearchProvider: SearchProvider = async (
  query,
  projectName = 'enercity',
  scope = 'global'
) => {
  const response = await fetch(
    `${config.api.baseUrl}${endpoints.search}?q=${query}&projectName=${projectName}&scope=${scope}`
  );

  if (!response.ok) {
    throw await response.json();
  }

  return (await response.json()).results;
};
